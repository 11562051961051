import { FC, PropsWithChildren } from 'react';
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";

import Dashboard from "./layouts/Dashboard";
import FileRequest from "./modules/FileRequest";
import { loginRequest } from "./authConfig";

const App: FC<{
    pca: IPublicClientApplication;
}> = ({ pca }) => {
    return (
        <MsalProvider instance={pca}>
            <Pages />
        </MsalProvider>
    );
}

const Pages = () => (
    <Routes>
        <Route path="/" element={<Dashboard><SecureRoute><Outlet /></SecureRoute></Dashboard>}>
            <Route index element={<FileRequest />} />
            <Route path="file-request" element={<FileRequest />} />
            <Route path="unauthorized" element={<>Unauthorized</>} />
            <Route path="*" element={<>Not Found</>} />
        </Route>
    </Routes>
)

const SecureRoute: FC<PropsWithChildren> = ({ children }) => {
    const authRequest = {
        ...loginRequest
    };
    
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={({ error }) => <>An error has occurred: {error?.message}</>}
            loadingComponent={() => <></>}
        >
            {children}
        </MsalAuthenticationTemplate>
    )
}

export default App;
