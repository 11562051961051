import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        authority: "https://login.microsoftonline.com/df505f9d-2555-419d-8720-93d1c7c0d8a9/oauth2/v2.0/authorize",
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '',
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["openid", "profile", "email", "offline_access", "user.read"]
};
